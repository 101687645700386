import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Peek a Boo Waxing",
  pageDescription: "A waxing salon streamlines their schedule.",
  title: "Ensuring a Smooth Operation",
  subTitle: `A small health & beauty business makes people feel good and saves time on operations.`,
  businessName: "Peek a Boo Waxing",
  industry: "Retail, Health & beauty",
  location: "Tucson, AZ",
  products: [
    spotonProducts.APPOINTMENTS,
    spotonProducts.CAPITAL,
    spotonProducts.PAYROLL,
  ],
  description:
    "Over the past 10 years, Peek a Boo Waxing has built up a loyal clientele and doubled its small but mighty staff to two aestheticians. Running a lean operation requires Michelle Tillis, owner of Peek a Boo Waxing, to find new ways to minimize time spent booking appointments and keeping track of sales.",
  quoteData: {
    imgName: "peek-a-boo-waxing.png",
    quote: `“Taking appointments online saves me time throughout the week. I don’t have a front desk person—it’s me doing all the booking. Now that 90% of our clients book online, it’s so much easier. SpotOn Appointments has done wonders for my business.”`,
    personName: "Michelle Tillis",
    personTitle: "Owner, Peek a Boo Waxing",
  },
  goal: `Tillis was looking for an all-in-one platform that would allow for online booking and time-saving accounting tools. She needed an online booking system that was easy for her longtime clients to use and a payment processing provider that didn’t charge exorbitant fees that would hobble her profit margins.`,
  solution: `With SpotOn, Tillis can oversee her sales, upcoming appointments, and accounting information without having to subscribe and remember passwords for multiple different platforms. Having all her business information in one place saves time when it comes to taking appointments, running payroll, and completing her taxes. What’s more, she was able to fund the expansion of her business with a seamless business business loan through SpotOn Capital, paid back with a percentage of her daily sales.`,
  results: {
    title: `The results`,
    stats: [
      { title: `6 hours`, description: `Saved weekly on admin` },
      {
        title: `90%`,
        description: `Appointments scheduled through online booking`,
      },
      {
        title: `$400`,
        description: `Savings in processing fees`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Fresh cuts and faster sales",
    imageName: "get-faded-barbershop.png",
    industry: resourceIndustries.RETAIL,
    tag: resourceTags.CASE_STUDY,
    date: "2022-09-22",
    href: "/case-studies/get-faded-barbershop",
    goals: [
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: "Financing and payment processing for an automotive shop",
    imageName: "24hr-diesel-repair.png",
    industry: resourceIndustries.RETAIL,
    tag: resourceTags.CASE_STUDY,
    date: "2022-08-12",
    href: "/case-studies/24hr-diesel-repair",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "A blooming nursery gives customers a faster experience",
    imageName: "unique-plants-and-palms.png",
    industry: resourceIndustries.RETAIL,
    tag: resourceTags.CASE_STUDY,
    date: "2022-07-13",
    href: "/case-studies/unique-plants-and-palms",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
